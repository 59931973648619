import React from 'react';

function SiteTitle() {

    return(
        <>
            <h1 className="site_title">Moooves</h1>   
        </>
    )
} 

export default SiteTitle